// import css from "./style.module.css";
// import fromLogo from "../img/mt-1330-logo.png";
// import { useEffect, useRef, useState } from "react";
// import sendEmail from "./sendMessage";
// import { RxHamburgerMenu } from "react-icons/rx";
// import { Link } from "react-router-dom";
// import { AiOutlineClose } from "react-icons/ai";
// export default function FirstBlock({ windowDimensions }) {
//   const [name, setName] = useState("");
//   const [phone, setPhone] = useState("");
//   const [email, setEmail] = useState("");
//   const formRef = useRef(null);
//   const [phonecheck, setPhonecheck] = useState(false);
//   const [namecheck, setNamecheck] = useState(false);
//   const [burgerCLick, setBurgerCLick] = useState(false);
//   const [clickCH, setClickCH] = useState(false);
//   const [validFrom, setValidFrom] = useState(false);
//   const [clickCHErr, setClickCHErr] = useState("Fill in all fields");
//   const [phonecheckErr, setPhonecheckErr] = useState(
//     "Your phone number is incorrect"
//   );
//   const [namecheckErr, setNamecheckErr] = useState(
//     "The name field cannot be empty"
//   );
//   const [emailcheckErr, setEmailcheckErr] = useState(
//     "The phone field cannot be empty"
//   );
//   const handleSubmit = (e) => {
//     sendEmail(formRef, e);
//     setName("");
//     setPhone("");
//     setEmail("");
//   };
//   const openBurgerMenu = () => {
//     setBurgerCLick(true);
//   };
//   const closeBurgerMenu = () => {
//     setBurgerCLick(false);
//   };
//   const blurHandle = (e) => {
//     switch (e.target.name) {
//       case "user_name":
//         setNamecheck(true);
//         break;
//       case "Phone":
//         setPhonecheck(true);
//         break;
//     }
//   };
//   const nameHandler = (e) => {
//     setName(e.target.value);
//     if (e.target.value.length < 2) {
//       setNamecheckErr("The name is too short.");
//       if (!e.target.value) {
//         setNamecheckErr("The name field cannot be empty");
//       }
//     } else {
//       setNamecheckErr("");
//     }
//   };
//   const namePhone = (e) => {
//     const value = e.target.value;
//     setPhone(value);
//     const num = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
//     if (
//       !value ||
//       (value && value.match(/\d/g) && value.match(/\d/g).length < 10)
//     ) {
//       setPhonecheckErr(`"Your phone number is incorrect"`);
//     } else {
//       setPhonecheckErr("");
//     }
//   };
//   useEffect(() => {
//     if (phonecheckErr || namecheckErr) {
//       setValidFrom(false);

//       setClickCHErr("Заповніть усі поля");
//     } else {
//       setValidFrom(true);
//       setClickCHErr("");
//     }
//   }, [phonecheckErr, namecheckErr, emailcheckErr]);
//   return (
//     <div id="section1" className={css.wrapFirstBlock}>
//       <header className={css.wrapHeader}>
//         <div className={css.wrapLogo}>
//           <img src={fromLogo} />
//           <p className={css.logoWrap}>TruckDispach</p>
//         </div>
//         {burgerCLick && (
//           <div className={css.burgerMenuWrap}>
//             <AiOutlineClose
//               onClick={closeBurgerMenu}
//               className={css.closeBurgerMenu}
//             />
//             <ul className={css.listStyle}>
//               <li className={css.liForNav}>
//                 <a href="#section1" className={css.liForNav}>
//                   Main
//                 </a>
//               </li>
//               <li className={css.liForNav}>
//                 <a href="#section2" className={css.liForNav}>
//                   Services
//                 </a>
//               </li>
//               <li className={css.liForNav}>
//                 <a href="#section3" className={css.liForNav}>
//                   About
//                 </a>
//               </li>
//               <li className={css.liForNav}>
//                 <a href="#section4" className={css.liForNav}>
//                   Contact
//                 </a>
//               </li>
//               <li className={css.liForNav}>
//                 <Link to="/terms-and-conditions" className={css.liForNav}>
//                   Terms and Conditions
//                 </Link>
//               </li>
//             </ul>
//           </div>
//         )}
//         {!windowDimensions && (
//           <RxHamburgerMenu
//             onClick={openBurgerMenu}
//             className={css.rxHamburgerMenu}
//           />
//         )}
//         {windowDimensions && (
//           <nav className={css.navigation}>
//             <ul className={css.navigationUL}>
//               <li className={css.liForNav}>
//                 <a href="#section1" className={css.liForNav}>
//                   Main
//                 </a>
//               </li>
//               <li className={css.liForNav}>
//                 <a href="#section2" className={css.liForNav}>
//                   Services
//                 </a>
//               </li>
//               <li className={css.liForNav}>
//                 <a href="#section3" className={css.liForNav}>
//                   About
//                 </a>
//               </li>
//               <li className={css.liForNav}>
//                 <a href="#section4" className={css.liForNav}>
//                   Contact
//                 </a>
//               </li>
//               <li className={css.liForNav}>
//                 <Link to="/terms-and-conditions" className={css.liForNav}>
//                   Terms and Conditions
//                 </Link>
//               </li>
//             </ul>
//           </nav>
//         )}

//         <a href="tel:+12677807627" className={css.nubberWrap}>
//           +1(267) 780-76-27
//         </a>
//       </header>
//       <div className={css.wrapformWithT}>
//         <div className={css.smallWrap}>
//           <div className={css.wrapF}>
//             <form
//               className={css.wrapFormSend}
//               onSubmit={(e) => {
//                 if (!validFrom) {
//                   e.preventDefault();
//                   setClickCH(true);
//                   setClickCHErr("Заповніть усі деталі");
//                 } else {
//                   setClickCHErr("");
//                   sendEmail(formRef, e);
//                   setName("");
//                   setPhone("");
//                 }
//               }}
//               ref={formRef}
//             >
//               <p className={css.pForm}>Contact us for free setup</p>
//               <div className={css.inpWrapAll}>
//                 <div className={css.wrapInp}>
//                   <label className={css.labelForIn}>Full name</label>
//                   {namecheck && namecheckErr && (
//                     <label style={{ color: "red" }} className={css.labelForIn}>
//                       {namecheckErr}
//                     </label>
//                   )}
//                   <input
//                     className={css.inputForm}
//                     value={name}
//                     onChange={(e) => nameHandler(e)}
//                     onBlur={(e) => blurHandle(e)}
//                     placeholder="Name"
//                     id="text"
//                     name="user_name"
//                   />
//                 </div>
//                 <div className={css.wrapInp}>
//                   <label className={css.labelForIn}>Phone number</label>
//                   {phonecheck && phonecheckErr && (
//                     <label style={{ color: "red" }} className={css.labelForIn}>
//                       {phonecheckErr}
//                     </label>
//                   )}
//                   <input
//                     className={css.inputForm}
//                     value={phone}
//                     onChange={(e) => namePhone(e)}
//                     onBlur={(e) => blurHandle(e)}
//                     placeholder="Phone"
//                     id="phone"
//                     name="Phone"
//                   />
//                 </div>
//                 <div className={css.wrapInp}>
//                   <label className={css.labelForIn}>Email</label>
//                   <input
//                     className={css.inputForm}
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     placeholder="Email"
//                     name="user_email"
//                     id="user_email"
//                   />
//                 </div>
//               </div>
//               <button type="submit" className={css.buttonRequest}>
//                 Send request
//               </button>
//               <p className={css.somePIn}>
//                 By providing your phone number, you consent to receive SMS
//                 notifications from FIRST LOGISTICS GROUP INC. Message frequency
//                 may vary. Standard message and data rates may apply. Reply
//                 "STOP" to opt-out or "HELP" for assistance.
//               </p>
//             </form>
//           </div>
//           <div className={css.wrapMainText}>
//             <p className={css.dispServP}>Freight Dispatch Service</p>
//             <a href="tel:+12677807627" className={css.nubberWrap}>
//               +1(267) 780-76-27
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
import { useState, useRef } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import css from "./style.module.css";
import fromLogo from "../img/mt-1330-logo.png";
import sendEmail from "./sendMessage";

export default function FirstBlock({ windowDimensions }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [burgerClick, setBurgerClick] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isChecked) {
      Swal.fire({
        icon: "warning",
        title: "Confirmation Required",
        text: "You need to agree to the terms by checking the box.",
        confirmButtonText: "Ok",
      });
      return;
    }

    sendEmail(formRef, e);
    setName("");
    setPhone("");
    setEmail("");
    setIsChecked(false);
  };

  const openBurgerMenu = () => {
    setBurgerClick(true);
  };

  const closeBurgerMenu = () => {
    setBurgerClick(false);
  };

  return (
    <div id="section1" className={css.wrapFirstBlock}>
      <header className={css.wrapHeader}>
        <div className={css.wrapLogo}>
          <img src={fromLogo} alt="Logo" />
          <p className={css.logoWrap}>TruckDispach</p>
        </div>
        {burgerClick ? (
          <div className={css.burgerMenuWrap}>
            <AiOutlineClose
              onClick={closeBurgerMenu}
              className={css.closeBurgerMenu}
            />
            <ul className={css.listStyle}>
              <li className={css.liForNav}>
                <a href="#section1" className={css.liForNav}>
                  Main
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section2" className={css.liForNav}>
                  Services
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section3" className={css.liForNav}>
                  About
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section4" className={css.liForNav}>
                  Contact
                </a>
              </li>
              <li className={css.liForNav}>
                <Link to="/terms-and-conditions" className={css.liForNav}>
                  Terms and Conditions
                </Link>
              </li>
              <li className={css.liForNav}>
                <Link to="/privacy-policy" className={css.liForNav}>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          !windowDimensions && (
            <RxHamburgerMenu
              onClick={openBurgerMenu}
              className={css.rxHamburgerMenu}
            />
          )
        )}
        {windowDimensions && (
          <nav className={css.navigation}>
            <ul className={css.navigationUL}>
              <li className={css.liForNav}>
                <a href="#section1" className={css.liForNav}>
                  Main
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section2" className={css.liForNav}>
                  Services
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section3" className={css.liForNav}>
                  About
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section4" className={css.liForNav}>
                  Contact
                </a>
              </li>
              <li className={css.liForNav}>
                <Link to="/terms-and-conditions" className={css.liForNav}>
                  Terms and Conditions
                </Link>
              </li>
              <li className={css.liForNav}>
                <Link to="/privacy-policy" className={css.liForNav}>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </nav>
        )}
        <a href="tel:+12677807627" className={css.nubberWrap}>
          +1(267) 780-76-27
        </a>
      </header>
      <div className={css.wrapformWithT}>
        <div className={css.smallWrap}>
          <form
            className={css.wrapFormSend}
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <p className={css.pForm}>Contact us for free setup</p>
            <div className={css.inpWrapAll}>
              <div className={css.wrapInp}>
                <label className={css.labelForIn}>Full name</label>
                <input
                  className={css.inputForm}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  name="user_name"
                />
              </div>
              <div className={css.wrapInp}>
                <label className={css.labelForIn}>Phone number</label>
                <input
                  className={css.inputForm}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Phone"
                  name="Phone"
                />
              </div>
              <div className={css.wrapInp}>
                <label className={css.labelForIn}>Email</label>
                <input
                  className={css.inputForm}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  name="user_email"
                />
              </div>
            </div>
            <div className={css.checkboxContainer}>
              <input
                type="checkbox"
                id="termsCheckbox"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label htmlFor="termsCheckbox" className={css.checkboxLabel}>
                I agree to the terms
              </label>
            </div>
            <p className={css.somePIn}>
              By checking this box, you agree to receive SMS text messages from
              TruckDispach. Reply STOP to opt-out at any time. Reply HELP for
              customer care contact information. Message and data rates may
              apply. Message frequency may vary.
            </p>
            <button type="submit" className={css.buttonRequest}>
              Send request
            </button>
          </form>
          <div className={css.wrapMainText}>
            <p className={css.dispServP}>Freight Dispatch Service</p>
            <a href="tel:+12677807627" className={css.nubberWrap}>
              +1(267) 780-76-27
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
