import css from "./privacy.module.css";

const FaqTe = () => {
  const longText = `Privacy Policy

Introduction
At TruckDispach, we deeply value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard the data you provide to us. We do not share, sell, or disclose your personal information to any third parties under any circumstances.

Information We Collect
We may collect personal information, including but not limited to:

Name
Phone number
Email address
This information is collected when you fill out our contact forms, sign up for our services, or communicate with us directly.

How We Use Your Information
The information we collect is used solely for:

Providing our services of finding loads for truck and trailer owners.
Communicating with you regarding our services.
Sending updates, confirmations, and reminders about your inquiries or bookings.
Improving our services based on your feedback.
SMS Consent
Phone numbers collected for SMS consent will not be shared with third parties or affiliates for marketing purposes under any circumstances. SMS messages will be sent only for updates and service-related notifications. Reply STOP to opt-out at any time.

Information Sharing and Disclosure
TruckDispach does not share, sell, or disclose any personal information to third parties, affiliates, or partners. Your data is used exclusively within our company to provide the services you request.

Data Security
We implement strict security measures to protect your personal data from unauthorized access, disclosure, or misuse. All data is securely stored and accessible only to authorized personnel for the purpose of providing services.

Your Rights
You have the right to access, update, or delete your personal information at any time. Please contact us at firstlogisticss@gmail.com for assistance.

Updates to This Policy
We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page with an updated revision date.

Contact Us
If you have any questions about this Privacy Policy, please contact us at:

Email: firstlogisticss@gmail.com
Phone: +1(267) 780-76-27`;

  const boldText = ["Privacy Policy"];

  return (
    <div className={css.wrapBlockFaq}>
      {longText.split("\n").map((paragraph, index) => (
        <p
          key={index}
          className={
            boldText.includes(paragraph.trim()) ? css.pInParaMain : css.pInPara
          }
        >
          {paragraph}
        </p>
      ))}
    </div>
  );
};

export default FaqTe;
