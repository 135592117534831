import css from "./style.module.css";
import FirstBlock from "./firstBlock";
import SecondBlock from "./secondBlock";
import TheWrap from "./threWrap";
import AboutUs from "./aboutUs";
import Footer from "./footer";
import Chat from "./chat/chat";

const Main = ({ windowDimensions }) => {
  return (
    <>
      <div className={css.allWrap}>
        <FirstBlock windowDimensions={windowDimensions} />
        <SecondBlock />
        <TheWrap />
        <AboutUs />
        <Footer />
        <Chat />
      </div>
    </>
  );
};
export default Main;
