import css from "./privacy.module.css";
const LastPolInFunc = () => {
  const longText = `SMS Communication Policy
Our SMS notification system complies with industry standards and regulations to ensure transparency and security in all communications. Below are the key aspects of our SMS policies:

Opt-In Messages
By subscribing to FIRST LOGISTICS GROUP INC SMS notifications, you agree to receive messages related to our services, updates, and promotions.
Example of Opt-In Message:

Thank you for subscribing to FIRST LOGISTICS GROUP INC SMS notifications. Message frequency may vary. Standard message and data rates may apply. Reply "STOP" to unsubscribe or "HELP" for assistance.

Opt-Out Messages
You can unsubscribe from SMS notifications at any time by replying "STOP" to any SMS.
Example of Opt-Out Message:

You have successfully unsubscribed from FIRST LOGISTICS GROUP INC SMS notifications. No further messages will be sent.

HELP Messages
If you need assistance with our SMS notifications, reply "HELP" to any SMS. You will receive support contact information.
Example of HELP Message:

For assistance with FIRST LOGISTICS GROUP INC SMS notifications, contact us at firstlogisticss@gmail.com or call (215) 543-3763.

Frequency and Fees
Messages are sent periodically based on your subscription preferences. Standard messaging and data rates may apply, depending on your carrier’s terms.


`;
  return (
    <div className={css.wrapBlockFaq}>
      {longText.split("\n").map((paragraph, index) => (
        <p key={index} className={index === 0 ? css.pInParaMain : css.pInPara}>
          {paragraph}
        </p>
      ))}
    </div>
  );
};
export default LastPolInFunc;
