import css from "./privacy.module.css";
const TermOfUse = () => {
  const longText = `Data Protection and Confidentiality
We prioritize the security and confidentiality of your personal data. All information collected is stored on secure servers and processed in compliance with data protection regulations. Your data will not, under any circumstances, be shared, sold, rented, or otherwise disclosed to third parties or affiliates for marketing or other purposes unless explicitly required by law.
Access to your data is strictly limited to authorized personnel within our organization who require it to perform their duties. We implement robust security measures, including encryption and access controls, to ensure your data remains protected against unauthorized access or misuse.
`;
  return (
    <div className={css.wrapBlockFaq}>
      {longText.split("\n").map((paragraph, index) => (
        <p key={index} className={index === 0 ? css.pInParaMain : css.pInPara}>
          {paragraph}
        </p>
      ))}
    </div>
  );
};
export default TermOfUse;
