import css from "./style.module.css";

export default function TheWrap() {
  return (
    <div id="section3" className={css.threWrap}>
      <div className={css.textBigWrap}>
        <p className={css.bigWrapP}>Logistics Capabilities</p>
        <div className={css.line}></div>
        <p className={css.smallP}>Dedicated dispatcher</p>
        <ul className={css.ulLi}>
          <li className={css.liList}>24/7 dispatch response</li>
          <li className={css.liList}>24/7 load search</li>
          <li className={css.liList}>24/7 billing support</li>
        </ul>
      </div>
      <div className={css.textSmallWr}>
        <p className={css.procentOne}>4%</p>
        <div className={css.lineSmall}></div>
        <p className={css.smDescP}>Payment for services</p>
        <p className={css.procentOne}>3%</p>
        <div className={css.lineSmall}></div>
        <p className={css.smDescP}>First six months</p>
        <p className={css.procentOne}>24/7</p>
        <div className={css.lineSmall}></div>
        <p className={css.smDescP}>Dispatch support. Accounting included</p>
      </div>
    </div>
  );
}
