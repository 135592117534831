// import css from "./style.module.css";
// import track from "../img/dsaf.jpeg";
// import { useEffect, useRef, useState } from "react";
// import sendEmail from "./sendMessage";
// export default function AboutUs() {
//   const [name, setName] = useState("");
//   const [phone, setPhone] = useState("");
//   const [email, setEmail] = useState("");
//   const formRef = useRef(null);
//   const [phonecheck, setPhonecheck] = useState(false);
//   const [namecheck, setNamecheck] = useState(false);
//   const [clickCH, setClickCH] = useState(false);
//   const [validFrom, setValidFrom] = useState(false);
//   const [clickCHErr, setClickCHErr] = useState("Fill in all fields");
//   const [phonecheckErr, setPhonecheckErr] = useState(
//     "Your phone number is incorrect"
//   );
//   const [namecheckErr, setNamecheckErr] = useState(
//     "The name field cannot be empty"
//   );
//   const [emailcheckErr, setEmailcheckErr] = useState(
//     "The phone field cannot be empty"
//   );
//   const handleSubmit = (e) => {
//     sendEmail(formRef, e);
//     setName("");
//     setPhone("");
//     setEmail("");
//   };
//   const blurHandle = (e) => {
//     switch (e.target.name) {
//       case "user_name":
//         setNamecheck(true);
//         break;
//       case "Phone":
//         setPhonecheck(true);
//         break;
//     }
//   };
//   const nameHandler = (e) => {
//     setName(e.target.value);
//     if (e.target.value.length < 2) {
//       setNamecheckErr(
//         "The name is too short. The name must contain more than 2 characters"
//       );
//       if (!e.target.value) {
//         setNamecheckErr("The name field cannot be empty");
//       }
//     } else {
//       setNamecheckErr("");
//     }
//   };
//   const namePhone = (e) => {
//     const value = e.target.value;
//     setPhone(value);
//     const num = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
//     if (
//       !value ||
//       (value && value.match(/\d/g) && value.match(/\d/g).length < 10)
//     ) {
//       setPhonecheckErr(`"Your phone number is incorrect"`);
//     } else {
//       setPhonecheckErr("");
//     }
//   };
//   useEffect(() => {
//     if (phonecheckErr || namecheckErr) {
//       setValidFrom(false);

//       setClickCHErr("Заповніть усі поля");
//     } else {
//       setValidFrom(true);
//       setClickCHErr("");
//     }
//   }, [phonecheckErr, namecheckErr, emailcheckErr]);
//   return (
//     <div id="section4" className={css.aboutWrap}>
//       <div className={css.firstBlockAbout}>
//         <p className={css.ourContact}>Our contacts</p>
//         <div className={css.contWr}>
//           <a href="tel:+12677807627" class={css.phone}>
//             Phone: +1(267) 780-76-27
//           </a>
//           <a href="mailto:firstlogisticss@gmail.com" class={css.phone}>
//             Email: firstlogisticss@gmail.com
//           </a>
//         </div>
//       </div>
//       <div className={css.formWrap}>
//         <div className={css.wrapF}>
//           <form
//             className={css.wrapFormSend}
//             onSubmit={(e) => {
//               if (!validFrom) {
//                 e.preventDefault();
//                 setClickCH(true);
//                 setClickCHErr("Заповніть усі деталі");
//               } else {
//                 setClickCHErr("");
//                 sendEmail(formRef, e);
//                 setName("");
//                 setPhone("");
//               }
//             }}
//             ref={formRef}
//           >
//             <p className={css.pForm}>Contact us for free setup</p>
//             <div className={css.inpWrapAll}>
//               <div className={css.wrapInp}>
//                 <label className={css.labelForIn}>Full name</label>
//                 {namecheck && namecheckErr && (
//                   <label style={{ color: "red" }} className={css.labelForIn}>
//                     {namecheckErr}
//                   </label>
//                 )}
//                 <input
//                   className={css.inputForm}
//                   value={name}
//                   onChange={(e) => nameHandler(e)}
//                   onBlur={(e) => blurHandle(e)}
//                   placeholder="Name"
//                 />
//               </div>
//               <div className={css.wrapInp}>
//                 <label className={css.labelForIn}>Phone number</label>
//                 {phonecheck && phonecheckErr && (
//                   <label style={{ color: "red" }} className={css.labelForIn}>
//                     {phonecheckErr}
//                   </label>
//                 )}
//                 <input
//                   className={css.inputForm}
//                   value={phone}
//                   onChange={(e) => namePhone(e)}
//                   onBlur={(e) => blurHandle(e)}
//                   placeholder="Phone"
//                 />
//               </div>
//               <div className={css.wrapInp}>
//                 <label className={css.labelForIn}>Email</label>
//                 <input
//                   className={css.inputForm}
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   placeholder="Email"
//                 />
//               </div>
//             </div>
//             <button type="submit" className={css.buttonRequest}>
//               Send request
//             </button>
//             <p className={css.somePIn}>
//               By providing your phone number, you consent to receive SMS
//               notifications from FIRST LOGISTICS GROUP INC. Message frequency
//               may vary. Standard message and data rates may apply. Reply "STOP"
//               to opt-out or "HELP" for assistance.
//             </p>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }
import { useState, useRef, useEffect } from "react";
import Swal from "sweetalert2";
import css from "./style.module.css";
import sendEmail from "./sendMessage";

export default function AboutUs() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false); // Стан чекбоксу
  const [validFrom, setValidFrom] = useState(false);
  const [clickCHErr, setClickCHErr] = useState("");
  const [phonecheckErr, setPhonecheckErr] = useState("");
  const [namecheckErr, setNamecheckErr] = useState("");
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isChecked) {
      Swal.fire({
        icon: "warning",
        title: "Confirmation Required",
        text: "You need to agree to the terms by checking the box.",
        confirmButtonText: "Ok",
      });
      return;
    }

    sendEmail(formRef, e);
    setName("");
    setPhone("");
    setEmail("");
    setIsChecked(false);
  };

  const blurHandle = (e) => {
    if (e.target.name === "user_name" && !e.target.value) {
      setNamecheckErr("The name field cannot be empty");
    } else if (e.target.name === "Phone" && e.target.value.length < 10) {
      setPhonecheckErr("Your phone number is incorrect");
    }
  };

  const nameHandler = (e) => {
    setName(e.target.value);
    if (e.target.value.length < 2) {
      setNamecheckErr(
        "The name is too short. It must contain more than 2 characters."
      );
    } else {
      setNamecheckErr("");
    }
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
    if (!e.target.value || e.target.value.length < 10) {
      setPhonecheckErr("Your phone number is incorrect");
    } else {
      setPhonecheckErr("");
    }
  };

  useEffect(() => {
    if (phonecheckErr || namecheckErr) {
      setValidFrom(false);
      setClickCHErr("Заповніть усі поля");
    } else {
      setValidFrom(true);
      setClickCHErr("");
    }
  }, [phonecheckErr, namecheckErr]);

  return (
    <div id="section4" className={css.aboutWrap}>
      <div className={css.firstBlockAbout}>
        <p className={css.ourContact}>Our contacts</p>
        <div className={css.contWr}>
          <a href="tel:+12677807627" className={css.phone}>
            Phone: +1(267) 780-76-27
          </a>
          <a href="mailto:firstlogisticss@gmail.com" className={css.phone}>
            Email: firstlogisticss@gmail.com
          </a>
        </div>
      </div>
      <div className={css.formWrap}>
        <div className={css.wrapF}>
          <form
            className={css.wrapFormSend}
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <p className={css.pForm}>Contact us for free setup</p>
            <div className={css.inpWrapAll}>
              <div className={css.wrapInp}>
                <label className={css.labelForIn}>Full name</label>
                <input
                  className={css.inputForm}
                  value={name}
                  onChange={nameHandler}
                  onBlur={blurHandle}
                  placeholder="Name"
                  name="user_name"
                />
                {namecheckErr && (
                  <span style={{ color: "red" }}>{namecheckErr}</span>
                )}
              </div>
              <div className={css.wrapInp}>
                <label className={css.labelForIn}>Phone number</label>
                <input
                  className={css.inputForm}
                  value={phone}
                  onChange={phoneHandler}
                  onBlur={blurHandle}
                  placeholder="Phone"
                  name="Phone"
                />
                {phonecheckErr && (
                  <span style={{ color: "red" }}>{phonecheckErr}</span>
                )}
              </div>
              <div className={css.wrapInp}>
                <label className={css.labelForIn}>Email</label>
                <input
                  className={css.inputForm}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  name="user_email"
                />
              </div>
            </div>
            <div className={css.checkboxContainer}>
              <input
                type="checkbox"
                id="termsCheckbox"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label
                htmlFor="termsCheckbox"
                className={css.checkboxLabel}
                onClick={(e) => e.preventDefault()} // Запобігання прокрутці
              >
                I agree to the terms
              </label>
            </div>
            <p className={css.somePIn}>
              By checking this box, you agree to receive SMS text messages from
              TruckDispach. Reply STOP to opt-out at any time. Reply HELP for
              customer care contact information. Message and data rates may
              apply. Message frequency may vary.
            </p>
            <button type="submit" className={css.buttonRequest}>
              Send request
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
