import css from "./privacy.module.css";
const PrivacyPalis = () => {
  const longText = `Transparency and Customer Rights
We believe in transparency regarding how your data is collected, used, and managed. You have the right to access, update, or request deletion of your personal data at any time. If you wish to exercise these rights, please contact us at firstlogisticss@gmail.com.
Additionally, you have the right to withdraw your consent to data processing at any time. Upon receiving such a request, we will promptly cease all processing activities and securely delete your data from our systems unless its retention is required by law.
`;
  return (
    <div className={css.wrapBlockFaq}>
      {longText.split("\n").map((paragraph, index) => (
        <p key={index} className={index === 0 ? css.pInParaMain : css.pInPara}>
          {paragraph}
        </p>
      ))}
    </div>
  );
};
export default PrivacyPalis;
