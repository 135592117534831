import css from "./privacy.module.css";
const NonDisc = () => {
  const longText = `Policy Updates and Compliance
These Terms and Conditions are subject to periodic updates to reflect changes in our services or compliance requirements. We encourage you to review them regularly to stay informed. Continuing to use our services constitutes your acceptance of any updates to these Terms and Conditions.
`;
  return (
    <div className={css.wrapBlockFaq}>
      {longText.split("\n").map((paragraph, index) => (
        <p key={index} className={index === 0 ? css.pInParaMain : css.pInPara}>
          {paragraph}
        </p>
      ))}
    </div>
  );
};
export default NonDisc;
