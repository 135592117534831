import css from "./privacy.module.css";
const VendorPoly = () => {
  const longText = `SMS Subscription and Data Usage
We collect and use your phone number exclusively to send SMS notifications about our services, updates, and promotions. Your data will not, under any circumstances, be shared, sold, or disclosed to third parties for marketing purposes.

All SMS communications comply with local and international laws regarding data protection. Messages are sent periodically, and frequency may vary based on user preferences. Standard messaging and data rates may apply.

You can unsubscribe at any time by replying "STOP" to any SMS or contacting us at firstlogisticss@gmail.com. For assistance, reply "HELP" to any SMS, or contact us directly.
`;
  return (
    <div className={css.wrapBlockFaq}>
      {longText.split("\n").map((paragraph, index) => (
        <p key={index} className={index === 0 ? css.pInParaMain : css.pInPara}>
          {paragraph}
        </p>
      ))}
    </div>
  );
};
export default VendorPoly;
