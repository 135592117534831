import css from "./style.module.css";
import first from "../img/dafasdfa.jpeg";
import second from "../img/dryvan refer - reefer.png";
export default function SecondBlock() {
  return (
    <div id="section2" className={css.wrapSecondBlock}>
      <div className={css.wrapTopBack}>
        <p className={css.pWrFor}>Who can work with us</p>
      </div>
      <div className={css.wrapWhoCan}>
        <p className={css.whoCanDescription}>
          Welcome to our platform specializing in matching cargo with dry van
          and reefer trucks. Whether you're a carrier seeking loads or a shipper
          in need of transportation services, we've got you covered. With our
          dedicated team and extensive network, we ensure efficient and reliable
          freight solutions tailored to your needs. Plus, take advantage of our
          limited-time offer: a commission rate of just 3% for the first six
          months. Join us today and experience seamless logistics management
          like never before.
        </p>
        <div className={css.wrapListDry}>
          <div className={css.wrapPic}>
            <img src={first} className={css.photoSt} />
            <p className={css.relativP}>Dry Van</p>
          </div>
          <div className={css.wrapPic}>
            <img src={second} className={css.photoSt} />
            <p className={css.relativP}>Reefer</p>
          </div>
        </div>
      </div>
    </div>
  );
}
