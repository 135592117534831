import { useEffect, useState } from "react";
import csss from "../style.module.css";
import css from "./privacy.module.css";
import FaqTe from "./faqTe";
import TermOfUse from "./termOfUse";
import PrivacyPalis from "./privacyPalis";
import RegionalPriv from "./regionalPriv";
import NonDisc from "./nonDisc";
import { useParams } from "react-router-dom";
import VendorPoly from "./vendorPoly";
import fromLogo from "../../img/mt-1330-logo.png";
import { useRef } from "react";
import { Link } from "react-router-dom";

import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";

import Footer from "../footer";
import LastPolInFunc from "./lastPolInFunc";
const Privacy = ({ totalQuantity, activeUser, windowDimensions }) => {
  const { id } = useParams(); // Отримайте id з URL
  console.log("ID from URL:", id);
  const [faq, setFaq] = useState(true);
  const [ship, setShip] = useState(false);
  const [payment, setPayment] = useState(false);
  const [terms, setTerms] = useState(false);
  const [returns, setReturns] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const formRef = useRef(null);
  const [phonecheck, setPhonecheck] = useState(false);
  const [namecheck, setNamecheck] = useState(false);
  const [burgerCLick, setBurgerCLick] = useState(false);
  const [lastPolIn, setLastPolIn] = useState(false);
  const [clickCH, setClickCH] = useState(false);
  const [validFrom, setValidFrom] = useState(false);
  const [clickCHErr, setClickCHErr] = useState("Fill in all fields");
  const [phonecheckErr, setPhonecheckErr] = useState(
    "Your phone number is incorrect"
  );
  const [namecheckErr, setNamecheckErr] = useState(
    "The name field cannot be empty"
  );
  const [emailcheckErr, setEmailcheckErr] = useState(
    "The phone field cannot be empty"
  );
  const handleSubmit = (e) => {
    setName("");
    setPhone("");
    setEmail("");
  };
  const openBurgerMenu = () => {
    setBurgerCLick(true);
  };
  const closeBurgerMenu = () => {
    setBurgerCLick(false);
  };
  const blurHandle = (e) => {
    switch (e.target.name) {
      case "user_name":
        setNamecheck(true);
        break;
      case "Phone":
        setPhonecheck(true);
        break;
    }
  };
  const nameHandler = (e) => {
    setName(e.target.value);
    if (e.target.value.length < 2) {
      setNamecheckErr("The name is too short.");
      if (!e.target.value) {
        setNamecheckErr("The name field cannot be empty");
      }
    } else {
      setNamecheckErr("");
    }
  };
  const namePhone = (e) => {
    const value = e.target.value;
    setPhone(value);
    const num = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (
      !value ||
      (value && value.match(/\d/g) && value.match(/\d/g).length < 10)
    ) {
      setPhonecheckErr(`"Your phone number is incorrect"`);
    } else {
      setPhonecheckErr("");
    }
  };
  useEffect(() => {
    if (phonecheckErr || namecheckErr) {
      setValidFrom(false);

      setClickCHErr("Заповніть усі поля");
    } else {
      setValidFrom(true);
      setClickCHErr("");
    }
  }, [phonecheckErr, namecheckErr, emailcheckErr]);
  useEffect(() => {
    if (id === "faqs") {
      faqFun();
    }
    if (id === "terms_of_use") {
      shipFun();
    }
    if (id === "privacy_policy") {
      payFun();
    }
    if (id === "regional_privacy_policy") {
      termsFun();
    }
    if (id === "non_descrimination_policy") {
      returnsFun();
    }
    if (id === "vendor_policy") {
      privacyFun();
    }
  }, [id]);
  const faqFun = () => {
    setFaq(true);
    setShip(false);
    setPayment(false);
    setTerms(false);
    setReturns(false);
    setPrivacy(false);
    setLastPolIn(false);
  };
  const shipFun = () => {
    setFaq(false);
    setShip(true);
    setPayment(false);
    setTerms(false);
    setReturns(false);
    setPrivacy(false);
    setLastPolIn(false);
  };
  const payFun = () => {
    setFaq(false);
    setShip(false);
    setPayment(true);
    setTerms(false);
    setReturns(false);
    setPrivacy(false);
    setLastPolIn(false);
  };
  const termsFun = () => {
    setFaq(false);
    setShip(false);
    setPayment(false);
    setTerms(true);
    setReturns(false);
    setPrivacy(false);
    setLastPolIn(false);
  };
  const returnsFun = () => {
    setFaq(false);
    setShip(false);
    setPayment(false);
    setTerms(false);
    setReturns(true);
    setPrivacy(false);
    setLastPolIn(false);
  };
  const privacyFun = () => {
    setFaq(false);
    setShip(false);
    setPayment(false);
    setTerms(false);
    setReturns(false);
    setPrivacy(true);
    setLastPolIn(false);
  };
  const lastPolFuncFun = () => {
    setFaq(false);
    setShip(false);
    setPayment(false);
    setTerms(false);
    setReturns(false);
    setPrivacy(false);
    setLastPolIn(true);
  };
  return (
    <>
      <header className={csss.wrapHeader}>
        <div className={csss.wrapLogo}>
          <img src={fromLogo} />
          <p className={csss.logoWrap}>TruckDispach</p>
        </div>
        {burgerCLick && (
          <div className={csss.burgerMenuWrap}>
            <AiOutlineClose
              onClick={closeBurgerMenu}
              className={css.closeBurgerMenu}
            />
            <ul className={csss.listStyle}>
              <li className={csss.liForNav}>
                <a href="/#section1" className={csss.liForNav}>
                  Main
                </a>
              </li>
              <li className={csss.liForNav}>
                <a href="/#section2" className={csss.liForNav}>
                  Services
                </a>
              </li>
              <li className={csss.liForNav}>
                <a href="/#section3" className={csss.liForNav}>
                  About
                </a>
              </li>
              <li className={csss.liForNav}>
                <a href="/#section4" className={csss.liForNav}>
                  Contact
                </a>
              </li>
              <li className={csss.liForNav}>
                <Link to="/terms-and-conditions" className={csss.liForNav}>
                  Terms and Conditions
                </Link>
              </li>
              <li className={csss.liForNav}>
                <Link to="/privacy-policy" className={csss.liForNav}>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        )}
        {!windowDimensions && (
          <RxHamburgerMenu
            onClick={openBurgerMenu}
            className={csss.rxHamburgerMenu}
          />
        )}
        {windowDimensions && (
          <nav className={csss.navigation}>
            <ul className={csss.navigationUL}>
              <li className={csss.liForNav}>
                <a href="/#section1" className={csss.liForNav}>
                  Main
                </a>
              </li>
              <li className={csss.liForNav}>
                <a href="/#section2" className={csss.liForNav}>
                  Services
                </a>
              </li>
              <li className={csss.liForNav}>
                <a href="/#section3" className={csss.liForNav}>
                  About
                </a>
              </li>
              <li className={csss.liForNav}>
                <a href="/#section4" className={csss.liForNav}>
                  Contact
                </a>
              </li>
              <li className={csss.liForNav}>
                <Link to="/terms-and-conditions" className={csss.liForNav}>
                  Terms and Conditions
                </Link>
              </li>
              <li className={csss.liForNav}>
                <Link to="/privacy-policy" className={csss.liForNav}>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </nav>
        )}

        <a href="tel:+12677807627" className={csss.nubberWrap}>
          +1(267) 780-76-27
        </a>
      </header>
      <div className={css.privacyWrap}>
        <div className={css.wrapsmalPriv}>
          <div className={css.wrapFirstBlock}>
            <p className={css.firstTextSuport}>Terms and Conditions</p>
            <p
              className={faq ? css.suporsChoiseGreen : css.suporsChoise}
              onClick={faqFun}
            >
              Data Collection and Usage
            </p>
            <p
              className={ship ? css.suporsChoiseGreen : css.suporsChoise}
              onClick={shipFun}
            >
              Data Protection and Confidentiality
            </p>
            <p
              className={payment ? css.suporsChoiseGreen : css.suporsChoise}
              onClick={payFun}
            >
              Transparency and Customer Rights
            </p>
            <p
              className={terms ? css.suporsChoiseGreen : css.suporsChoise}
              onClick={termsFun}
            >
              Opt-in, Opt-out, and Consent Withdrawal
            </p>
            <p
              className={returns ? css.suporsChoiseGreen : css.suporsChoise}
              onClick={returnsFun}
            >
              Policy Updates and Compliance
            </p>
            <p
              className={privacy ? css.suporsChoiseGreen : css.suporsChoise}
              onClick={privacyFun}
            >
              SMS Subscription and Data Usage
            </p>
            <p
              className={lastPolIn ? css.suporsChoiseGreen : css.suporsChoise}
              onClick={lastPolFuncFun}
            >
              SMS Communication Policy
            </p>
          </div>
          <div className={css.wrapBlockText}>
            {faq && <FaqTe />}
            {ship && <TermOfUse />}
            {payment && <PrivacyPalis />}
            {terms && <RegionalPriv />}
            {returns && <NonDisc />}
            {privacy && <VendorPoly />}
            {lastPolIn && <LastPolInFunc />}
            <div className={css.wrapBlockFaq}>
              Contact information: <br />
              Email Address: firstlogisticss@gmail.com <br />
              Support Phone Number: (215) 543-3763 <br />
              Support Website Link:
              https://truckdispach.com/terms-and-conditions
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Privacy;
