import logo from "./logo.svg";
import "./App.css";
import css from "./components/style.module.css";
import FirstBlock from "./components/firstBlock";
import SecondBlock from "./components/secondBlock";
import TheWrap from "./components/threWrap";
import AboutUs from "./components/aboutUs";
import Footer from "./components/footer";
import Chat from "./components/chat/chat";
import { Routes, Route, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Main from "./components/main";
import Thank from "./components/thank";
import Privacy from "./components/privacyP/privacy";
import PrivacyOne from "./components/privacyPone/privacyOne";
function App() {
  const [windowDimensions, setWindowDimensions] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1100) {
        setWindowDimensions(false);
      } else {
        setWindowDimensions(true);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Main windowDimensions={windowDimensions} />} />
      <Route
        path="/thank"
        element={<Thank windowDimensions={windowDimensions} />}
      />
      <Route
        path="/terms-and-conditions"
        element={<Privacy windowDimensions={windowDimensions} />}
      />
      <Route
        path="/privacy-policy"
        element={<PrivacyOne windowDimensions={windowDimensions} />}
      />
    </Routes>
  );
}

export default App;
