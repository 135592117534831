import css from "./privacy.module.css";
const RegionalPriv = () => {
  const longText = `Opt-in, Opt-out, and Consent Withdrawal
By subscribing to FIRST LOGISTICS GROUP INC SMS notifications, you explicitly consent to receive periodic messages related to our services, updates, and promotions. Message frequency may vary. Standard message and data rates may apply based on your mobile carrier’s policies.

If you wish to unsubscribe, reply "STOP" to any SMS or contact us at firstlogisticss@gmail.com. Upon unsubscribing, no further messages will be sent.

For assistance, reply "HELP" to any SMS, or contact us at firstlogisticss@gmail.com or call (215) 543-3763.

We will process opt-out and HELP requests promptly to ensure a seamless experience. Your personal data will remain secure, and any data related to marketing campaigns will be permanently deleted upon your request.
`;
  return (
    <div className={css.wrapBlockFaq}>
      {longText.split("\n").map((paragraph, index) => (
        <p key={index} className={index === 0 ? css.pInParaMain : css.pInPara}>
          {paragraph}
        </p>
      ))}
    </div>
  );
};
export default RegionalPriv;
