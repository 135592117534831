import css from "./privacy.module.css";

const FaqTe = () => {
  const longText = `Data Collection and Usage
We collect personal data from customers primarily through our feedback forms, contact forms, and SMS subscription services. This information may include, but is not limited to, names, phone numbers, email addresses, and details provided through your inquiries or interactions. The purpose of collecting this data is to provide seamless customer support, respond to your queries, deliver relevant updates, and share promotional materials related to our services.
Your data will be used strictly for these purposes and will never be utilized in ways that contradict our Privacy Policy or applicable laws. By providing your information, you consent to its collection, storage, and use in compliance with these Terms and Conditions.`;

  const boldText = ["Data Collection and Usage"];

  return (
    <div className={css.wrapBlockFaq}>
      {longText.split("\n").map((paragraph, index) => (
        <p
          key={index}
          className={
            boldText.includes(paragraph.trim()) ? css.pInParaMain : css.pInPara
          }
        >
          {paragraph}
        </p>
      ))}
    </div>
  );
};

export default FaqTe;
