import css from "./style.module.css";
import fromLogo from "../img/mt-1330-logo.png";
import { useRef, useState } from "react";
import sendEmail from "./sendMessage";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
export default function Thank({ windowDimensions }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const formRef = useRef(null);
  const [burgerCLick, setBurgerCLick] = useState(false);

  const handleSubmit = (e) => {
    sendEmail(formRef, e);
    setName("");
    setPhone("");
    setEmail("");
  };
  const openBurgerMenu = () => {
    setBurgerCLick(true);
  };
  const closeBurgerMenu = () => {
    setBurgerCLick(false);
  };
  return (
    <div id="section1" className={css.wrapFirstBlock}>
      <header className={css.wrapHeader}>
        <div className={css.wrapLogo}>
          <img src={fromLogo} />
          <p className={css.logoWrap}>TruckDispach</p>
        </div>
        {burgerCLick && (
          <div className={css.burgerMenuWrap}>
            <AiOutlineClose
              onClick={closeBurgerMenu}
              className={css.closeBurgerMenu}
            />
            <ul className={css.listStyle}>
              <li className={css.liForNav}>
                <a href="#section1" className={css.liForNav}>
                  Main
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section2" className={css.liForNav}>
                  Services
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section3" className={css.liForNav}>
                  About
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section4" className={css.liForNav}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
        {!windowDimensions && (
          <RxHamburgerMenu
            onClick={openBurgerMenu}
            className={css.rxHamburgerMenu}
          />
        )}
        {windowDimensions && (
          <nav className={css.navigation}>
            <ul className={css.navigationUL}>
              <li className={css.liForNav}>
                <a href="#section1" className={css.liForNav}>
                  Main
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section2" className={css.liForNav}>
                  Services
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section3" className={css.liForNav}>
                  About
                </a>
              </li>
              <li className={css.liForNav}>
                <a href="#section4" className={css.liForNav}>
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        )}

        <a href="tel:+12677807627" className={css.nubberWrap}>
          +1(267) 780-76-27
        </a>
      </header>
      <div className={css.wrapformWithT}>
        <div className={css.smallWrap}>
          <div className={css.wrapMainTextThank}>
            <p className={css.dispServPth}>Thank you for your interest</p>
            <a href="tel:+12677807627" className={css.nubberWrap}>
              +1(267) 780-76-27
            </a>
            <Link to="/" className={css.nubberWrapT}>
              Main
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
